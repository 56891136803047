import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { BiographyPage } from "../components/pageLayout/BiographyPage";

export const biographyPages = graphql`
  query BiographyPagesQuery {
    biographyPages: allSanityBiography(
      sort: { fields: [startDate], order: DESC }
      filter: { slug: { current: { ne: null } }, startDate: { ne: null } }
    ) {
      edges {
        node {
          id
          startDate
          endDate
          type
          mainImage {
            asset {
              id
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
          title
          slug {
            current
          }
          location {
            lat
            lng
          }
        }
      }
    }
  }
`;

const Biography = ({ data }) => {
  const { biographyPages } = data;
  return (
    <>
      <Seo title="Biography" />
      <BiographyPage biographyPages={biographyPages.edges} />
    </>
  );
};

export default Biography;
