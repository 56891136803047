import React, { useEffect, useState } from "react";
// import { Link } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
import { BiographyList } from "./BiographyList";
import { Map } from "../../common/Map";

import * as styles from "./BiographyPage.module.scss";

const BiographyPage = ({ biographyPages }) => {
  const [places, setPlaces] = useState([]);
  const [all, setAll] = useState(true);
  const defaultPlaces = biographyPages
    .filter(({ node }) => node.location?.lng && node.location?.lat)
    .map(({ node }) => ({
      name: node.title,
      longitude: node.location?.lng,
      latitude: node.location?.lat,
    }));
  useEffect(() => {
    if (defaultPlaces) {
      setPlaces(defaultPlaces);
    } else {
      setPlaces([]);
    }
  }, []);

  // useEffect(() => {
  //   if (props && props.data) {
  //     setPlaces(props.data.markdownRemark.frontmatter.places)
  //   } else {
  //     setPlaces([])
  //   }
  // }, [props])

  const solo = biographyPages.filter(({ node }) => node.type === "solo");
  const group = biographyPages.filter(({ node }) => node.type === "group");
  const talk = biographyPages.filter(({ node }) => node.type === "talk");
  const education = biographyPages.filter(
    ({ node }) => node.type === "education"
  );
  const publication = biographyPages.filter(
    ({ node }) => node.type === "publication"
  );
  const collection = biographyPages.filter(
    ({ node }) => node.type === "collection"
  );
  const residency = biographyPages.filter(
    ({ node }) => node.type === "residency"
  );
  const press = biographyPages.filter(({ node }) => node.type === "press");

  return (
    <>
      <div className={styles.projectHeader}>
        <h1 className="visually-hidden">Biography</h1>
      </div>
      <section className={styles.introduction}>
        <p>
          Underlying the creative practice of British, interdisciplinary artist
          Alistair McClymont is a deep concern for beauty and reason. Through
          artistic experimentation, discovery, and collaboration with
          scientists, McClymont questions the frontiers between art and science
          and what it means to be human in an era defined by big science and
          data driven research.
        </p>
        <p>
          His work spans a variety of media from sculptural installation,
          painting, and photography, to video, new media digital art, and the
          publication of scientific research papers.
        </p>
        <p>
          Many of his Artworks take the form of direct demonstration or
          experimentation. By isolating phenomena from the world, McClymont
          represents and re-evaluates them in novel creative contexts. Concepts
          are allowed to define the forms they take, and projects are designed
          to highlight the delicate, generative balance between order and chaos
          in the very systems used to create them.
        </p>
        <p>
          For more details click on{" "}
          <a href="#solo-exhibitions">solo exhibitions</a>,{" "}
          <a href="#group-exhibitions">group exhibitions</a>,{" "}
          <a href="#talks">talks</a>, <a href="#publications">publications</a>,{" "}
          <a href="#collections">collections</a> ,{" "}
          <a href="#residencies">residencies</a>, <a href="#press">press</a> and{" "}
          <a href="#education">education</a>.
        </p>
      </section>

      <div className={styles.biographyWrapper}>
        <section className={styles.biographyList}>
          <section id="solo-exhibitions">
            <h2>Solo Exhibitions</h2>
            {solo && (
              <BiographyList
                list={solo}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="group-exhibitions">
            <h2>Group Exhibitions</h2>
            {group && (
              <BiographyList
                list={group}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="talks">
            <h2>Talks</h2>
            {talk && (
              <BiographyList
                list={talk}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="publications">
            <h2>Publications</h2>
            {publication && (
              <BiographyList
                list={publication}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="collections">
            <h2>Collections</h2>
            {collection && (
              <BiographyList
                list={collection}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="residencies">
            <h2>Residencies</h2>
            {residency && (
              <BiographyList
                list={residency}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="press">
            <h2>Press</h2>
            {press && (
              <BiographyList
                list={press}
                setPlaces={setPlaces}
                setAll={setAll}
              />
            )}
          </section>
          <section id="education">
            <h2>Education</h2>
            {education && (
              <BiographyList
                list={education}
                setPlaces={setPlaces}
                setAll={setAll}
                startDate={false}
                endDate={true}
              />
            )}
          </section>
        </section>
        <section className={styles.biographyMapWrapper}>
          <div className={styles.biographyMap}>
            {!all && (
              <button
                className={styles.allButton}
                onMouseDown={() => {
                  setPlaces(defaultPlaces);
                  setAll(true);
                }}
              >
                Show all locations
              </button>
            )}
            <Map places={places} className={styles.mapBox} />
          </div>
        </section>
      </div>
    </>
  );
};

export { BiographyPage };
